.tab-transition-enter {
  opacity: 0;
  transform: translateY(-20px);
}

.tab-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.tab-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.tab-transition-exit-active {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 300ms, transform 300ms;
}

.title-border {
  margin-top: 10px;
}


.nav-item .nav-link {
  background-color: transparent;
  color: #4D6D9A;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.3s;
  border-radius: 4px;
}

.nav-item .nav-link.active {
  background-color: #4D6D9A;
  /* Active background color */
  color: #fff;
  /* Active text color */
}


h5:hover .underline-animation {
  transform: scaleX(1);
  transform-origin: bottom left;
}