.loader-container {
 
  backdrop-filter: blur(1px);
  position: absolute;
  /* background-color: red; */
  width: 100%;
  height: 100vh;
  position: fixed;
  padding-top: 200px;
  left: -10px;


}

.leaf-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.leaf {
  width: 50px;
  height: 50px;
  background-color: #34D399;
  border-radius: 50%;
  margin: 0 10px;
  animation: leaf-animation 1s infinite ease-in-out;
  transform-origin: center center;
}

@keyframes leaf-animation {
  0% {
    transform: translateY(0) rotate(0deg);
  }
  25% {
    transform: translateY(-15px) rotate(30deg);
  }
  50% {
    transform: translateY(0) rotate(0deg);
  }
  75% {
    transform: translateY(15px) rotate(-30deg);
  }
  100% {
    transform: translateY(0) rotate(0deg);
  }
}

.leaf-loader .leaf:nth-child(2) {
  animation-delay: -0.5s;
}
