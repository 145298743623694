.ax-checkbox-group {
    height:auto;
    width:100%; 
    position:relative;
}

.ax-checkbox-group.md{
    width: 400px;
}

.ax-checkbox-group.right{
    float: right;
    margin-left: 10px;
}

/* Children Start */
.ax-checkbox-group>.ant-checkbox-group-item {
    height:30px;
    width:48%; 
    margin-top:5px;
    margin-bottom:5px;
    position:relative;  
}

.ax-checkbox-group>.ant-checkbox-group-item>span:last-child {
    position:absolute;
    height:30px;
   /* line-height: 30px;*/
    font-size:14px;
    left:25px;
    top:0px;
    display: flex;
    align-items: center;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.ax-checkbox-group.size14>.ant-checkbox-group-item>span:last-child {
    font-size:14px;
}

.ax-checkbox-group.size10>.ant-checkbox-group-item>span:last-child {
    font-size:11px;
}

.ax-checkbox-group.size10>.ant-checkbox-group-item>.ant-checkbox-checked .ant-checkbox-inner {
    background-image: none!important;
}

.ax-checkbox-group>.ant-checkbox-group-item>.ant-checkbox { 
    margin-top:5px;
    width:20px !important;
    height:20px !important;
}

.ax-checkbox-group>.ant-checkbox-group-item>.ant-checkbox>.ant-checkbox-input {    
    width:20px !important;
    height:20px !important;    
}

.ax-checkbox-group>.ant-checkbox-group-item>.ant-checkbox>.ant-checkbox-inner {    
    width:20px !important;
    height:20px !important;   
}
/* Children End */


/* button type styled start */

.ax-checkbox-group.button{
    display: flex;
}
.ax-checkbox-group.button>.ant-checkbox-group-item{
    flex-grow: 1;
    flex-basis: 0;
    text-align: center;
    position: relative;
    margin: 0!important;
    padding: 0!important;
}

.ax-checkbox-group.button>.ant-checkbox-group-item>.ant-checkbox { 
    margin-top: 0px!important;
    width:100%!important;
    height:100%!important;
}

.ax-checkbox-group.button>.ant-checkbox-group-item>.ant-checkbox>.ant-checkbox-input {    
    width:100%!important;
    height:100%!important;    
}

.ax-checkbox-group.button>.ant-checkbox-group-item>.ant-checkbox>.ant-checkbox-inner {    
    width:100%!important;
    height:100%!important;  
    border-radius: 0px!important;
}

.ax-checkbox-group.button>.ant-checkbox-group-item>span:nth-child(2){
    position: absolute;
    top: 50%;  
    left: 50%;
    transform: translate(-50%, -50%);
}


.ax-checkbox-group.button>.ant-checkbox-group-item.ant-checkbox-wrapper-checked>span:nth-child(2){
    color: var(--inavitas001)
}

/* button type styled end */