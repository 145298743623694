/* Card container */
.simple-card {
  border-radius: 10px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  overflow-y: scroll;
  padding: 5px;
  overflow-x: hidden;
  scrollbar-width: none;
  height: auto;
}
 
.collapsed {
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  width: auto;
  overflow-y: scroll;
  min-height: 200px;
  margin: 30px;
  margin-right: 10px;
  padding: 15px;
  overflow-x: hidden;
  transition: width 0.2s ease;
  margin-bottom: 20px;

 
}
.expanded {
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  width: auto;
  overflow-y: scroll;
  min-height: 200px;
  margin: 10px;
  margin-bottom: 20px;
  padding: 10px;
  overflow-x: hidden;
  transition: width 0.2s ease;
 
}
 
/* Card header (for title, description, and image) */
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-left: 20px;
  margin-bottom: -10px;
}
.card-title {
  font-size: 1.5rem;
  margin-top: 10px;
}
.card-description-expanded {
  font-size: 1rem;
  color: #4D6D9A;
  margin-top: 30px;
  padding: 6px;
  margin-bottom: -50px;
  letter-spacing: 2px;

}

.card-description-expanded::first-letter {
  font-size: 1.5rem;  /* Increase the size */
  font-weight: bold;  /* Make it bold */
  margin-top: 5px;  /* Adjust spacing */
}

.card-description-collpased {
  font-size: 1rem;
  margin-top: 40px;
  padding: 6px;
  margin-bottom: -50px; 
  margin-left: 20px;
  letter-spacing: 2px;

}

.card-description-collpased::first-letter {
  font-size: 1.5rem;  /* Increase the size */
  font-weight: bold;  /* Make it bold */
  margin-top: 5px;  /* Adjust spacing */
}

 
/* Card image */
.card-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}
 
/* Card body */
.card-body {
  margin-top: 20px;
}
 
 