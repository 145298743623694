.ax-loading {
    width:100%;      
}

.ant-spin-dot-item {
    color:#FECC00 !important; /* Replace #007bff with your desired color */
}

/* Optionally, change the text color for the loading tip */
.ant-spin-text {
    color:#FECC00; /* Replace with your desired text color */
}