.ant-select {
    width:inherit;
    cursor: pointer !important;
}
ant-select>.ant-select-selector {    
    cursor: pointer !important;
    height:30px !important;
    width:100% !important;
    min-width: 80px;

}

/* Items Start */

.ax-select.minified>.ant-select-selector {
    max-height:32px !important; 
    overflow:hidden;
}
.ax-select.minified.ant-select-multiple>.ant-select-selector>.ant-select-selection-item {
   min-width:50px !important; 
   padding-left:0 !important;
}
  
.ax-select.minified.ant-select-multiple>.ant-select-selector>.ant-select-selection-item>.ant-select-selection-item-content {
   /* max-width:28px !important;*/
    margin-left:0 !important;
}

.ax-select.minified.ant-select-multiple>.ant-select-selector>.ant-select-selection-item>.ant-select-selection-item-remove {
    position: absolute;
    top:0px !important;
    right:0px !important;
    width:22px !important;
    height:22px !important;    
    margin-left:0 !important;  
  }


/* SelectedItems End */


/* Position Start */

.ax-select.left{
    float: left;
}
.ax-select.right{
    float: right;  
}
.ax-select.center{
   margin: 0 auto;
}

/* Position End */

/* Size Start */

.ax-select.full{
    width:100% !important;
}
.ax-select.sm>.ant-select-selector{
    width: 75px;
}
.ax-select.md>.ant-select-selector{
    width: 150px;
}
.ax-select.lg>.ant-select-selector{
    width: 250px;
}
.ax-select.xl>.ant-select-selector{
    width: 450px;
}

/* Size End */

/* Height Start */

.ax-select.minimum {
    max-height:30px;
    overflow: hidden;
}

.ax-select.minimum>.ant-select-selector {
    max-height:30px;   
}

/* Height End */


/* allow clear styles start */

.ax-select.ant-select-multiple.ant-select-allow-clear>.ant-select-clear{
    opacity: 1;
    left: 75px;
    width: 22px;
    height: 22px;
    background-color: var(--inavitas005);
    top: 10px;
}

.ax-select.ant-select-multiple.ant-select-allow-clear>.ant-select-clear:hover{
    background-color: var(--inavitas006); 
}

.ax-select.ant-select-multiple.ant-select-allow-clear>.ant-select-selector>.ant-select-selection-item>.ant-select-selection-item-content{
    padding-right: 5px!important;
}

.ax-select.ant-select-multiple.ant-select-allow-clear>.ant-select-clear>span{
    /* background-image: url(./../../../assets/icons/icon-close.svg); */
    background-size: contain;
    width: 22px;
    height: 22px;
}
.ax-select.ant-select-multiple.ant-select-allow-clear>.ant-select-clear>span>svg{
    display: none;
}

.ax-select.ant-select-multiple.ant-select-allow-clear>.ant-select-selector>.ant-select-selection-item  ~ .ant-select-selection-search {
    margin-left: 40px;
    padding-left: -15px;
}

.ax-select.ant-select-multiple.ant-select-allow-clear  .ant-select-selection-search>input{
    width: 100%!important;
    display: block;
    min-width: 70px!important;
}


.ant-select-selection-placeholder {
    position: absolute !important;
    left:10px !important;    
    height:30px !important;
    line-height:30px !important;
    padding-left: 0px !important;
    color:var(--inavitasText) !important;
    font-size:14px !important;
    opacity:0.5 !important;
}
/* allow clear styles end */
