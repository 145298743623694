.multi-slider-container {
  width: 100%;
  height: 78vh;
  padding: 5px;
  z-index: 10;
  overflow-y: hidden;
}

.slider-section {
  position: relative;
}

.slider-title {
  text-align: center;
  font-weight: 600;
  color: #4d6d9a;
}

.slider-navigation {
  z-index: 5;
  position: fixed;
  right: 20px;
  top: 60px;


  display: flex;
  /* right: 10px; */
}

.prev-btn,
.next-btn {
  background-color: #4d6d9a;
  color: #fff;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 10px;
  transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform for smooth scaling */
  margin: 10px;
}

.prev-btn:hover,
.next-btn:hover {
  transform: scale(1.1); /* Smoothly scale the button when hovered */
}

.prev-btn:disabled,
.next-btn:disabled {
  background-color: #ccc; /* Light grey color when disabled */
  cursor: not-allowed; /* Change cursor to indicate it's disabled */
  opacity: 0.5; /* Make the disabled button semi-transparent */
}

/* Optional: Add focus styles for better accessibility */
.prev-btn:focus,
.next-btn:focus {
  outline: none;
}

.slider-wrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-content {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  position: relative;
}

.slide-item {
  flex: 1 0 100%;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-top: 0;
  position: relative;
}

.slide-card {
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
  overflow-x: hidden;
}

/* .header-title {
  border-bottom: 2px solid #fecc00;
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  width: fit-content;
  font-weight: bold;
  text-align: left;
  display: block;
  color: #212b54;
}
 */
.slide-description {
  color: #4d6d9a;
}

/* Horizontal Volume Progress Bar */
.stepContainer {
  position: fixed;
  bottom: 10px;
  left: 0;
  right: 0;
  background-color: #ccc; /* Inactive background color */
  border-radius: 5px; /* Optional: for rounded corners */
  margin: 0 auto;
}

/* Responsive design for smaller screens */
/* Step Indicator */

/* Step Indicator */
/* Step Indicator */
/* Step Indicator */
/* Step Indicator */
.step-indicator {
  display: flex;
  justify-content: space-between; /* Space the steps evenly */
  align-items: center;
  margin-top: 5px;
  position: fixed;
  z-index: 1000;
  width: 85%; /* You can adjust this to suit your layout */
}

.step-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}

.step {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 0 5px;
}

.step .circle {
  background-color: #fff;
  border: 2px solid #dce3f5;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #dce3f5;
  transition: all 0.3s ease;
}

.step.active .circle {
  background-color: #4d6d9a;
  border-color: #4d6d9a;
  color: #fff;
  transform: scale(1.1);
}

.step .label {
  margin-top: 20px;
  font-size: 12px;
  color: #dce3f5;
}

.step.active .label {
  color: #4d6d9a;
}

.step-line {
  height: 2px;
  background-color: #dce3f5;
  transition: background-color 0.3s ease;
  position: absolute;
  z-index: -1;
  width: 89%; 
  left: 80px; /* Position the line to start at the left of the first circle */
  top: 10%;
}

.step-line.active-line {
  background-color: #4d6d9a; /* Active line color */
}
