.dashboard_container{
    overflow-x: hidden;
    padding: 30px;
}


.dashboard-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    padding: 20px;
}
.fees-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-top: 10px;
    margin-left: 30px;
    /* padding: 10px; */
}

.column {
    flex: 1;
    min-width: 200px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
}
.column-1 {
    flex: 1;
    min-width: 200px;
    /* box-sizing: border-box; */
    border-radius: 8px;
}

.viewCardFees {
    display: flex; /* Use flexbox to align items in a single row */
    align-items: center; /* Vertically align content */
    justify-content: space-between; /* Distribute space between elements */
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease; /* Smooth transition for hover effects */
}
.cardContent {
    padding: 0; /* Reset padding for a tighter layout */
    display: flex; /* Align title and amount in one line */
    justify-content: space-between; /* Ensure equal spacing between title and amount */
    width: 100%;
    align-items: center; /* Center vertically */
}

.card_title {
    color: white;
    font-weight: bold;
    margin: 0;
    font-size: 14px; /* Reduced font size */
}

.card_amount {
    color: white;
    font-weight: normal;
    font-size: 12px; /* Same size as title for consistency */
    margin-left: 10px; /* Optional: Adds slight spacing between title and amount */
}


.viewCard {
    display: grid; /* Use CSS Grid to divide into two columns */
    grid-template-columns: 1fr 2fr; /* The first column (image) takes 1 part, the second (content) takes 2 parts */
    align-items: center; /* Vertically center both the image and content */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease; /* Smooth transition for hover effects */
}


.viewCard1 {
    background: linear-gradient(to right, #a1c4fd, #8ab8cd); /* Light blue to light sky blue */
}

.viewCard2 {
    background: linear-gradient(to right, #82a460, #5aab94); /* Light green to mint green */
}

.viewCard3 {
    background: linear-gradient(to right, #ff9a8b, #ffb3ba); /* Peach to light pink */
}

.viewCard4 {
    background: linear-gradient(to right, #e6a1e6, #c2a9ff); /* Lavender to light purple */
}
.viewCard4 {
    background: linear-gradient(to right, #675067, #4c3e71); /* Lavender to light purple */
}

/* Hover effects */
.viewCard:hover {
    transform: scale(1.05); /* Slightly scale up the card */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Add stronger shadow */
}

/* .cardContent {
    padding-left: 15px; 
}
.card_title{
    color: white;
} */
.three-column-layout {
    display: flex;
    gap: 10px;
    width: 100%;
    background: linear-gradient(to right, #fefbd8, #f9e2a8);
    border-radius: 10px;
    margin-left: -5px;


  }
  
  .bar_Column {
    flex: 1;
    min-width: 400px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
  }
  .two_container {
    display: flex;
    justify-content: space-between;
  }
  
  .left-column,
  .right-column {
    width: 48%; /* Adjust the width as needed */
    padding: 10px;
    box-sizing: border-box;
  }
  
 