@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes shimmer {
  0% { background-position: -1000px 0; }
  100% { background-position: 1000px 0; }
}

@keyframes submitPulse {
  0% { transform: scale(1); box-shadow: 0 0 0 0 rgba(59, 130, 246, 0.4); }
  50% { transform: scale(0.98); box-shadow: 0 0 0 15px rgba(59, 130, 246, 0); }
  100% { transform: scale(1); box-shadow: 0 0 0 0 rgba(59, 130, 246, 0); }
}

@keyframes exitForm {
  0% { transform: translateX(0) rotate(0); opacity: 1; }
  100% { transform: translateX(-20px) rotate(-3deg); opacity: 0; }
}

@keyframes enterForm {
  0% { transform: translateX(20px) rotate(3deg); opacity: 0; }
  100% { transform: translateX(0) rotate(0); opacity: 1; }
}

@keyframes fadeSlideUp {
  0% { transform: translateY(10px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

.animate-shimmer {
  background: linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent);
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
}

.animate-submit {
  animation: submitPulse 0.8s ease-in-out;
}

.animate-exit {
  animation: exitForm 0.3s ease-out forwards;
}

.animate-enter {
  animation: enterForm 0.3s ease-out forwards;
}

.animate-fade-slide-up {
  animation: fadeSlideUp 0.5s ease-out forwards;

}

.bg-mesh {
  /* background-image: radial-gradient(circle at center center, rgb(107, 33, 168) 0%, rgb(76, 29, 149) 21%, rgb(67, 56, 202) 48%, rgb(37, 99, 235) 75%, rgb(59, 130, 246) 100%); */
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
}




@keyframes gradientBG {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}