.ax-radio { 
    
}

/* Children Start */

.ax-radio>.ant-radio>.ant-radio-inner::after {
    background-color: var(--inavitas012) !important;
}

.ax-radio>.ant-radio-checked>.ant-radio-inner {
    border-color: var(--inavitas012) !important;
}

/* Children End */