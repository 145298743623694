body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.title-border {
  /* background-color: #f2f5f3; */
  padding: 5px;
  /* border-radius: 5px; */
  border-bottom-style: solid;
  width: fit-content;
  border-color: #7a101a;
  /* border-radius: 8px; */
}

.weekDays-selector input {
  display: none !important;
}

.weekDays-selector input[type="checkbox"] + label {
  display: inline-block;
  border-radius: 6px;
  background: #dddddd;
  height: 40px;
  width: 30px;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.weekDays-selector input[type="checkbox"]:checked + label {
  background: #2ad705;
  color: #ffffff;
}

.unitOption {
  margin-top: 10px;
}

.dropdown-card {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  transition: box-shadow 1s ease;
  background: #e8eae6;
}

.dropdown-card:hover {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.5);
}

/* .expanded svg {
  transform: rotate(90deg);
  transition: transform 0.4s ease;
}

.collapsed svg {
  transform: rotate(0deg);
  transition: transform 0.4s ease;
} */

.dropdown-menu-container {
  transition: transform 0.6s ease;
}

.tree-view-card {
  -webkit-box-shadow: 4px 5px 17px -4px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 4px 5px 17px -4px rgba(0, 0, 0, 0.21);
  box-shadow: 4px 5px 17px -4px rgba(0, 0, 0, 0.21);
}

.circular-template-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  /* justify-content:space-around; */
  /* gap: 3rem; */
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}
.footer {
  display: flex;
  /* justify-content:center; */
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.school-logo {
  width: 6rem;
  margin-left: 1rem;
}
.title {
  position: absolute;
  top: 25px;
  /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
}

.date {
  top: 45px;
  right: 40px;
}

.container {
  position: relative;
}

.signature {
  position: absolute;
  bottom: 5px;
  left: 20px;
}

/* styles.css */
.icon-transition {
  transition: transform 0.3s ease;
}

.icon-transition.expanded-u {
  transform: rotate(90deg);
}

.school-details {
  text-align: center;
  flex: 1;
}

.location {
  text-align: center;
}

.footer {
  border-top: 1px solid #ccc;
  border-bottom: none;
}

.school-details p,
.signature {
  /* margin: 0; */
  /* text-align: center;  */
}

.content {
  text-align: left;
}

.title {
  text-align: center;
  margin: 20px 0;
}

.date {
  text-align: right;
}

.body-header {
  margin-top: 20px;
  font-weight: bold;
}

.body {
  margin-top: 10px;
}

.signature {
  margin-top: 20px;
  font-size: 12px;
  font-weight: bold;
}

.receipt-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.receipt-header,
.receipt-details,
.payment-info,
.receipt-footer {
  margin-bottom: 20px;
}

.receipt-header {
  text-align: center;
}

.company-name {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.company-details p {
  margin: 4px 0;
}

.receipt-details,
.payment-info {
  border-top: 1px solid #eee;
  padding-top: 10px;
}

.receipt-details h2,
.payment-info h2 {
  margin: 0 0 10px;
  font-size: 18px;
  border-bottom: 1px solid #eee;
  padding-bottom: 5px;
}

.details-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.details-row p {
  margin: 0;
}

.receipt-footer {
  border-top: 1px solid #eee;
  padding-top: 10px;
}

.signature-row {
  display: flex;
  justify-content: space-between;
}

.signature-row div {
  width: 45%;
}

.signature {
  /* border-top: 1px solid #000; */
  padding-top: 15px;
  margin-top: 20px;
  text-align: center;
  margin-left: 16px;
}
/* unit dropdown */
.dropdown-card {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.collapse.show {
  display: block ; /* Ensure it's visible */
  visibility: visible ;
  opacity: 1 ;
  height: auto;
  overflow-y: scroll;
  scrollbar-width: none;
}
