.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: gray;
  width: 100%;
  z-index: 100;
  

}

.navbar-right {
  display: flex;
  align-items: center;
  
}

.navbar-left {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 10px;
}


.icon {
  font-size: 1.3rem;
  color: #9161a8;
  cursor: pointer;
  margin-top: -15px;

}
.menu-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 25px;
  height: 40px;
}

/* Burger Icon */
.burger-icon {
  position: relative;
  width: 30px;
  height: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;

}


.burger-icon span {
  display: block;
  width: 100%;
  height: 3px;
  background-color:#4D6D9A;
  border-radius: 3px;
  transition: all 1s linear;
}

/* Arrow Transformation */
.burger-icon.open span:nth-child(1) {
  transform: rotate(45deg) translate(1px, 1px); /* Top bar rotates downwards */
  width: 50%;
  transition:width  1s linear;

}
.burger-icon.open span:nth-child(2) {
  opacity: 1; 
  transform:  translate( -9px);
  width: 70%;
}

.burger-icon.open span:nth-child(3) {
  transform: rotate(-45deg) translate(1px, -1px); /* Bottom bar rotates upwards */
  width: 50%; /* Shortens the bottom bar to form an arrow tip */
}
.burger-icon.close span:nth-child(3) {
  width: 70%; 
}
.menu-icon-container.open {
  width: 40px;

}

.navbar-brand {
  font-size: 1.5rem;
  color: gray;
}

.navbar-menu {
  list-style: none;
  display: flex;
  gap: 4px;
  margin: 0;
  padding: 0;
  margin-top: 10px;
}

.navbar-menu li a {
  color: gray;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.navbar-menu li a:hover {
  background-color: #C4B5FD;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .navbar-menu {
      display: none;
  }
}

.menu-icon-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-left: 20px;
  margin-top: -1px;
}


.circle-icon {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  /* background-image: url(../../..//app/assets/images/logo10.jpg); */
  background-size: cover;
  cursor: pointer;
  transition: transform 0.3s ease;
  margin-top: -5px;
  margin-right:10px;
}

.headerCard {
  width: 200px;
  height: 300px;
  overflow-y: scroll;
  padding: 5px;
  background: #191c29;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 60px; /* Adjust the position relative to the navbar */
  left: 80%;
  animation: cardpopupIn 0.2s linear forwards; /* New popup animation */
  z-index: 2000;
  border-radius: 10px;
}



.card.hidden {
  animation: cardpopupOut 0.2s ease-out forwards; /* New popup-out animation */
}
/* 
@keyframes slideIn {
  from {
    transform: translateY(-150%);
  }
  to {
    transform: translateY(0); 
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0%); 
  }
  to {
    transform: translateY(-150%); 
  }
} */

.headerCard::-webkit-scrollbar {
  width: 0px;
}

.headerCard::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners for the scrollbar */
}

.headerCard::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darken the thumb color on hover */
}



.submenu {
  list-style: none;
  padding: 0;
  margin: 0;
  color: gray;
}

.submenu li a {
  display: block;
  padding: 10px 20px;
  color: gray;
  text-decoration: none;
}

.submenu li a:hover {
  background-color: #f0f0f0;
  border-radius: 5px;

}





/* styles.css */
/* .icon-transition {
  transition: transform 0.3s ease;
}

.icon-transition.expanded-u {
  transform: rotate(90deg);
} */

.unitOption {
  margin-top: 10px;
}

.dropdown-card {
  max-height: 400px;
  /* overflow-y: auto; */
  padding: 10px;
  margin: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 1s ease;
}

.dropdown-card:hover {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.5);
}

/* Ensure the dropdown menu is aligned properly */
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1050;
}

/* Styling for the toggle icon */
/* .icon-transition {
  vertical-align: middle;
  margin-left: 5px;
} */

/* Styling for each item */
.unit-item {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

/* For nested dropdowns */
.dropdown-menu-container {
  margin-left: 20px;
}




.dropdown-menu-container {
  transition: transform 0.6s ease;
}

.dropdown-menu-container .unit-item {
  /* padding: 5px 0; Add some padding between items */
  cursor: pointer;
}

.smooth-fade {
  transition: opacity 0.6s ease-in-out;
}

.dropdown-menu-container .unit-item:hover {
  background-color: #f1f1f1; /* Hover effect for subunit items */
}

/* .profile-details{
  margin-top: -5px;
} */
/* Apply the popup-in animation */
.profile-card {
  animation: cardpopupIn 0.2s linear forwards; /* New popup animation */
}

/* Apply the popup-out animation */
.profile-card-hidden {
  animation: cardpopupOut 0.2s linear forwards; /* New popup-out animation */
}

/* Keyframes for the 'popup-in' animation */
@keyframes cardpopupIn {
  from {
      opacity: 0;
      transform: scale(0) translateY(-20px); /* Start smaller and above */
  }
  to {
      opacity: 1;
      transform: scale(1) translateY(0); /* Pop to normal size */
  }
}

/* Keyframes for the 'popup-out' animation */
@keyframes cardpopupOut {
  from {
      opacity: 1;
      transform: scale(1) translateY(0); /* Normal size */
  }
  to {
      opacity: 0;
      transform: scale(0) translateY(-20px); /* Shrink and move up */
  }
}


