.custom-dropdown {
    position: relative;
    /* width: 100%; */
    margin-bottom: 16px;
}

.custom-select-dropdown {
    width: 100%;
    padding: 12px 16px;
    font-size: 16px;
    color: #333;
    background-color: #f8f9fa;
    border: 1px solid #ced4da;
    border-radius: 8px;
    appearance: none;
    outline: none;
    cursor: pointer;
    transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.custom-select-dropdown:hover {
    border-color: #007bff;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.custom-select-dropdown:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.2);
}

/* Styling individual options */
.custom-option {
    font-size: 15px;
    background-color: #ffffff;
    color: #333;
    padding: 8px;
    border-radius: 8px;
}

/* Custom arrow styling */
.custom-arrow {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 14px;
    color: #333;
    pointer-events: none;
}

/* Optional hover effect for options (not directly supported on <option>, but enhances dropdown styling) */
.custom-select-dropdown option:hover {
    background-color: #f0f0f0; /* Hover effect */
    
}
.label-color {
    color: gray; /* Hover effect */
}
