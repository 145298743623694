/* TreeView.css */
.tree-view-container {
    position: fixed;
    top: 60px; /* Adjust as needed */
    right: 20px;
    width: 300px;
    background: #191c29;
    color: #f0f0f0;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 10px;
    transform: scale(0) translateY(-20px);
    opacity: 0;
    transition: all 0.3s ease-out;
    z-index: 9999;
  }
  
  .tree-view-container.show {
    transform: scale(1) translateY(0);
    opacity: 1;
  }
  
  .tree-view-container.hide {
    transform: scale(0) translateY(-20px);
    opacity: 0;
  }
  
  .tree-view-header {
    cursor: pointer;
    font-size: 18px;
    padding: 10px;
    background-color: #2c2f3d;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .tree-view {
    padding-left: 20px;
    transition: all 0.3s ease;
  }
  
  .tree-view.expanded {
    padding-left: 40px;
  }
  
  .tree-view.collapsed {
    padding-left: 0;
  }
  
  .tree-view-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .tree-item {
    cursor: pointer;
    padding: 5px;
  }
  
  .tree-item:hover {
    background-color: #444;
  }
  
  .tree-view-sub-list {
    list-style: none;
    padding-left: 20px;
    transition: opacity 0.3s ease-out;
  }
  
  .tree-view-sub-list li {
    padding: 5px;
    background-color: #333;
    margin-top: 5px;
  }
  