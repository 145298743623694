.multi-select-container {
    position: relative;
    width: 100%;
    max-width: 300px;
    margin: 5px;
    font-family: Arial, sans-serif;
  }
 
  /* Selected items styling */
  .selected-items {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 5px;
  }
 
  .selected-item {
    display: flex;
    align-items: center;
    padding: 5px 5px;
    border-radius: 15px;
    font-size: 14px;
  }
 
  .remove-btn {
    margin-left: 5px;
    cursor: pointer;
    font-weight: bold;
  }
 
  /* Placeholder text */
  .dropdown-placeholder {
    padding: 5px;
    background: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    text-align: left;
  }
 
  /* Dropdown menu styling */
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
 
  .search-input {
    width: 100%;
    padding: 8px;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 14px;
    outline: none;
  }
 
  .dropdown-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 200px;
    overflow-y: auto;
  }
 
  .dropdown-item {
    padding: 8px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
 
  .dropdown-item input[type="checkbox"] {
    margin-right: 8px;
  }
 
  .dropdown-item.focused {
    background-color: #f0f0f0;
  }
 
  /* Light and dark theme */
  .multi-select-container.light .dropdown-menu {
    background: #fff;
    color: #000;
  }
 
  .multi-select-container.dark .dropdown-menu {
    background: #333;
    color: #fff;
  }


