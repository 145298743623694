
.ax-image {     
    background-color:var(--inavitas012);
    background-size:cover;
    /* display:none; */
    background-size: cover !important;
}

.ax-image.true {
    display:inline-block;
}

.ax-image.left {
    float:left;
    margin-right:10px;
}
.ax-image.right {
    float:right;
    margin-left:10px;
}


.ax-image.sm {
    width:30px;
    height:30px;
}

.ax-image.sm-border{
    width:30px;
    height:30px;
    border-radius: 30px;
}

.ax-image.md {
    width:40px;
    height:40px;
}

.ax-image.lg {
    width:50px;
    height:50px;
}

.ax-image.xl {
    width:100px;
    height:100px;
}

.ax-image.green {
    background-color: var(--inavitas021);
}

.ax-image.orange {
   background-color: var(--inavitas017);
}

.ax-image.gray {
    background-color: var(--inavitas008);
}

.ax-image.lightGray {
    background-color: var(--inavitas004);
}

