.ax-input {
  width:100%;
  min-height:30px;
}

/* Size Start */
.ax-input.sm { 
  width:180px;
}



/* Size End */

/* Position Start */
.ax-input.right {
  float:right;
  margin-left:10px;
}
/* Position End */


/* Children Start */

.ax-input>input {
  height:30px;
  padding-left:10px !important;
}

.ax-input>.ant-input-affix-wrapper {
  padding:0 !important;
  min-height:30px;
}


/* Desing Start */ 





.ax-input.numerator>.ant-input-number {
  display: block !important;
  height:30px !important;
  border-radius:3px;
  overflow:hidden;
}

.ax-input.numerator>.ant-input-number>.ant-input-number-handler-wrap {
  position:absolute !important;
  left:0px !important;
  top:0px !important;
  width:100% !important;
  height:28px !important;
  opacity:1 !important;
  z-index:9;
  border:none !important;
}

.ax-input.numerator>.ant-input-number>.ant-input-number-handler-wrap>.ant-input-number-handler-down {
  position:absolute;
  left:0px !important;
  top:0px !important;
  width:28px !important;
  height:100% !important;
  opacity:1 !important;
  background-color:var(--inavitas006);
  /* background-image:url('../../../assets/icons/icon-minus.svg'); */
  background-size:cover;
  border:none !important;
  cursor:pointer;
}

.ax-input.numerator>.ant-input-number>.ant-input-number-handler-wrap>.ant-input-number-handler-down>span {
  display:none !important;
}

.ax-input.numerator>.ant-input-number>.ant-input-number-handler-wrap>.ant-input-number-handler-down:hover{
  height:28px !important;
  background-color:var(--inavitas012);
}

.ax-input.numerator>.ant-input-number>.ant-input-number-handler-wrap>.ant-input-number-handler-up {
  position:absolute;
  right:0px !important;
  top:0px !important;
  width:28px !important;
  height:100% !important;
  opacity:1 !important;
  background-color:var(--inavitas006);
  /* background-image:url('../../../assets/icons/icon-plus.svg'); */
  background-size:cover;
  border:none !important;
  cursor:pointer;
}

.ax-input.numerator>.ant-input-number>.ant-input-number-handler-wrap>.ant-input-number-handler-up>span {
  display:none !important;
}

.ax-input.numerator>.ant-input-number>.ant-input-number-handler-wrap>.ant-input-number-handler-up:hover{
  height:28px !important;
  background-color:var(--inavitas012);  
}

.ax-input.numerator>.ant-input-number>.ant-input-number-input-wrap {
  position:absolute !important;
  left:28px !important;
  top:0px !important;
  width:calc(100% - 56px) !important;
  height:28px !important;
  opacity:1 !important;
  z-index:10;
}

.ax-input.numerator>.ant-input-number>.ant-input-number-input-wrap>input {
  text-align: center !important;
  height:28px !important;
  line-height:28px;
  font-size:14px;
}

/* Design End */

/* Children End */

.ant-form-item-control-input{
  min-height: auto!important;
}

.ant-form-item .ant-input-group .ant-select, .ant-form-item .ant-input-group .ant-cascader-picker{
  width: auto!important;
  min-width: 125px;
}

.ant-input-number{
  width: 100%!important;
}


/* toolRefreshh style start */

.ax-input.tool-refresh { 
width:90px!important;
display: flex;
align-items: center;
}

.ax-input.tool-refresh>.ant-input-affix-wrapper {
min-height:20px;
height: 20px;
}

.ax-input.tool-refresh .ant-input-number-input{
height: 20px!important;
}
.ax-input.tool-refresh>.ant-input-number{
height: 25px;
line-height: 25px!important;
border-radius: 3px;
border: none!important;
font-size: 14px;
}

.ax-input.tool-refresh .ant-input-number:hover{
border-color: transparent!important;
border-right-width: 0px!important;

}

.ax-input.tool-refresh .ant-input-number-focused{
box-shadow:none!important;
border-color:transparent!important;
outline: none!important;
border: none!important;
}

.ax-input.tool-refresh>.ant-input-number .ant-input-number-handler-wrap{
opacity: 1!important;
background: transparent!important;
border-left: none!important;
color:var(--inavitas001)
}

.ax-input.tool-refresh>.ant-input-number .ant-input-number-handler-wrap>.ant-input-number-handler-down{
border-top:none!important;
}

.ant-input-number-handler:active.ant-input-number-handler-up:hover{
height: 50%!important;
}

.ax-input.tool-refresh>.ant-input-number .ant-input-number-handler-wrap>.ant-input-number-handler-up{
height: 50%!important;
}

.ax-input.tool-refresh>.ant-input-number .ant-input-number-handler-wrap>.ant-input-number-handler-up>.ant-input-number-handler-up-inner{
margin-top: -3px!important;
color: var(--inavitas010);
}

.ax-input.tool-refresh>.ant-input-number .ant-input-number-handler-wrap>.ant-input-number-handler-down>.ant-input-number-handler-down-inner{
top: unset!important;
-webkit-transform: none!important;
transform: none!important;
margin-top: 1px!important;
color: var(--inavitas010);
}

.ax-input.tool-refresh>.ant-input-number .ant-input-number-handler-wrap>.ant-input-number-handler:active{
background: transparent!important;
}


/* toolRefreshh style end */


.ax-input>input::placeholder {  
color:var(--inavitasText) !important;
opacity:0.5 !important;
font-size:14px !important;
}