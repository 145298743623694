.table-responsive {
  /* overflow-y: auto;  */
  min-height: 300px; 
  overflow: hidden;
  max-height: auto;
}

/* Custom scrollbar for WebKit browsers (Chrome, Edge, Safari) */
.table-responsive::-webkit-scrollbar {
  width: 0px; /* Adjust scrollbar width */
}

.table-responsive::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}
.table-container {
  width: 100%;
  /* overflow-y: scroll; */
  scrollbar-width: 3px;

}


.table-container.expanded {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 10px;
  scrollbar-width: none;
  transition: 0.2s width linear;
}

.table-container.collapsed {
  width: 100%;
  scrollbar-width: 0;
  transition: 0.2s width linear;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}
/* .table-hover tbody {
  height: 300px;

} */

.table thead th {
  background-color: transparent;
  color: #4D6D9A;
  text-align: left;
  padding: 10px 10px;
  border: none;
}

.table thead {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.icon-container {
  height: 30px; /* Ensure a fixed height */
  display: flex; 
  align-items: center;
  justify-content: center;
  gap: 2px; 
}


.dynamic-button-container {
  display: flex;
  gap: 2px;
  /* Adjust spacing between buttons */
}

#table-search {
  /* width: 150px; */
  height: 32px;
}



.table-title-container {
  width: fit-content;
  margin-bottom: 1.5rem;
  background-color: #f4f4f9;
  border-left: 6px solid rgb(147, 112, 219);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.table-title {
  font-size: 15px;
  color: rgb(3, 3, 3);
  font-weight: 600;
}

.card-description {
  font-size: 1rem;
  color: #4D6D9A;
  margin-top: 10px;
  padding: 6px;
  /* margin-left: 5px; */
  letter-spacing: 2px;
}

.card-description::first-letter {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 5px;
}

.form-control {
  font-size: 14px;
  color: gray;

}

.table tbody td {
  background-color: none;
  border: none;
  padding-left: 10px;
  /* display: flex; */
  text-align: left;
}


.table tbody tr {
  height: 30px; /* Set a fixed height */
  padding: 0; /* Remove any additional padding */
  transition: transform 0.2s ease;
  line-height: 30px;

}


.table tbody tr:hover {
  transform: scale(1);
  overflow-x: hidden;
}

/* General pagination container styling */
.pagination {
  display: flex;
  list-style: none;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
  padding: 0;
}

/* Pagination items styling */
.pagination li {
  margin: 0 5px;
}

.pagination li a {
  display: block;
  padding: 4px 4px;
  text-decoration: none;
  color: gray;
  font-size: 11px;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

/* Hover effect for pagination items */

/* Active page styling */
.pagination .active a {
  background-color: #212B54;
  color: white;
}

/* Disabled previous/next button styling */
.pagination .disabled a {
  color: #ccc;
  cursor: not-allowed;
  border-color: #ddd;
}

/* Previous and Next arrow buttons */
.pagination li svg {
  font-size: 16px;
  vertical-align: middle;
}

/* Dropdown button styling */
/* Style for DropdownButton */
.custom-dropdown {
  font-size: 8px;
  /* Adjust font size */
  border: 0.5px solid #D3D3D3;
  border-radius: 5px;

}

.custom-dropdown .dropdown-toggle:focus,
.custom-dropdown .dropdown-toggle:active {
  border: #D3D3D3;
  color: gray;
}

.custom-dropdown .dropdown-toggle {
  border-color: transparent;
  font-size: 12px;
  color: gray;

}

/* In your custom.css */

.dropdown-menu {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100px;
  outline: none;
  border: 1px solid gray;
  color: gray;
}

.dropdown-menu.active {
  border: 1px solid gray;
}

.showing-rows {
  text-decoration: none;
  color: #9370db;
}

.dropdown-item {
  font-size: 12px;
  color: gray;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.dropdown-item:hover {
  background-color: #212B54;
  color: white;
}

.dropdown-item:active {
  /* background-color: #0056b3; */
  color: white;
}