.ax-time-picker {
    height: 30px;               /* Sets the height of the time picker input */
    width: 100%;                /* Makes the input take up 100% of the width of its container */
    min-width: 180px;           /* Ensures the input will not be smaller than 180px wide */
    float: left;                /* Floats the element to the left (useful if you want it to align next to other elements) */
  }
  

/* Children Start */ 
.ax-time-picker>.ant-picker {
    width:100% !important;  
 }
 

 .ax-date-picker.sm{
    width: 120px!important;
    min-width:120px!important;
 }
/* Children End */ 

.ax-date-picker>.ant-picker-input>.ant-picker-suffix{
    display: block!important;
}