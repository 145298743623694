.checkbox-group {
    display: flex;
    flex-direction: column;
  }
  
  .checkbox-item {
    margin-bottom: 1rem;
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;

  }
  
  input[type="checkbox"] {
    margin-right: 0.5rem;
    cursor: pointer;
    border: none;
  }
  
  input[type="checkbox"]:focus {
    outline: none;
  }
  
  .checkbox-text {
    font-weight: 500;
  }
  .checkbox-description {
    /* margin: 0.2rem 0 0 1.8rem; Indent the description slightly for readability */
    color: #666;
    /* font-size: 0.9rem; */
  }
  
  .checkbox-item:hover .checkbox-text {
    color: #0056b3;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .checkbox-item {
      margin-bottom: 0.8rem;
    }
  }
