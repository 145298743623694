/* Date Picker Wrapper */
.form-check-input {
    width: 17px;
    height: 17px;
}

.ax-date-picker {
    height: 30px;
    width: 100%;
    min-width: 180px;
    background-color: #f0f0f0; 
    border-radius: 6px; 
    
}

/* Input Field */
.ax-date-picker > .ant-picker-input {
    padding-left: 5px !important;
    background-color: #ffffff; 
}

/* Input Field Placeholder */
.ax-date-picker > .ant-picker-input > input::placeholder {
    color: var(--inavitasText) !important;
    opacity: 0.5 !important;
    font-size: 14px !important;
}

.ant-picker-panel-container {
    background-color: #ffffff !important; 
    border: none !important;
}

/* Date Cells */
.ant-picker-content > tbody > tr > td {
    color: var(--inavitasTitle);
    opacity: 0.5;
    background-color: #f9f9f9; /* Background color for date cells */
}

.ant-picker-content > tbody > tr > td.ant-picker-cell-in-view {
    opacity: 1;
    background-color: #e6f7ff; /* Highlight background color for selected date */
}

/* Header and Footer Background */
.ant-picker-header {
    background-color: #f0f0f0; /* Light grey background for the header */
    border-bottom: var(--inavitasBorder) !important;
}

.ant-picker-footer {
    background-color: #f0f0f0; /* Light grey background for the footer */
    border-top: var(--inavitasBorder) !important;
    border-bottom: var(--inavitasBorder) !important;
}

/* Today Button Style */
.ant-picker-today-btn {
    color: var(--inavitasTitle);
    background-color: #f0f0f0; /* Light background for the Today button */
}

/* Adjust the position of the range picker dropdown */
.ant-picker-dropdown-range {
    left: 100px !important;
}

/* Overall Picker Border */
.ant-picker {
    /* border: var(--inavitasBorder); */
    border-color: lightgray !important;
    background-color: #ffffff; /* Background color for the main DatePicker container */
}
